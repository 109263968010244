/* eslint-disable */

<template>
  <div class="">
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="vx-card p-12">
          <div class="vx-row">
            <div class="vx-col w-1/4">
              <div class="flex flex-col items-center justify-center" style="gap: 50px">
                <div class="flex items-center">
                  <div class="line-across-top" id="hello1" />
                  <div class="rounded-lg bg-light main-border flex flex-col items-center justify-center p-3 z-10">
                    <img
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/456907876376510464/aruba-logo.png"
                      width="125"
                      alt=""
                      class="mb-4"
                    />
                    <h3 class="font-bold mb-2">[SWITCH]</h3>
                    <h3 class="font-bold text-dark mb-1">Aruba CX</h3>
                    <h3 class="font-bold text-dark">9300 Series</h3>
                  </div>
                </div>
                <div
                  class="rounded-lg flex flex-col items-center justify-center p-3 z-10 bg-cover bg-light main-border"
                  style="min-height: 150px"
                >
                  <img
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/456907876376510464/sonicwall-logo.png"
                    width="125"
                    alt=""
                    class="mb-6"
                  />
                  <h3 class="font-bold mb-12">[UTM/FW]</h3>
                  <h3 class="font-bold text-dark">NSa 2650</h3>
                </div>
                <div class="rounded-lg bg-light main-border flex flex-col items-center justify-center p-3 z-10">
                  <img
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/456907876376510464/comcast-business-logo.png"
                    width="125"
                    alt=""
                    class="mb-2"
                  />
                  <img
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/456907876376510464/comcast-router.png"
                    width="125"
                    alt=""
                    class="mb-4"
                  />
                  <h3 class="font-bold">[ROUTER]</h3>
                </div>
                <div class="line-down-left" />
              </div>
            </div>
            <div class="vx-col w-1/4" style="padding-top: 120px">
              <div class="flex flex-col items-center justify-end rounded-lg">
                <div class="flex justify-center items-center">
                  <div class="line-down-other" />
                </div>
                <div class="flex flex-col border-2 rounded-lg p-4" style="gap: 15px; border-style: dashed; border-color: #f6f588">
                  <div class="rounded-lg flex flex-col items-center justify-center z-10" style="background: #f6f588">
                    <div class="flex flex-col items-center justify-center p-4">
                      <img :src="items[0].image" width="45" alt="" class="mb-2" />
                      <div style="max-width: 200px">
                        <h4 class="font-bold text-black text-center mb-1">{{ items[0].name }}</h4>
                        <h5 class="font-light text-black text-center">IP: {{ items[0].ip }}</h5>
                      </div>
                    </div>
                    <vs-button
                      style="border-radius: 0 0 6px 6px"
                      color="#181d2a"
                      icon-pack="feather"
                      icon="icon-external-link"
                      class="w-full"
                      @click="
                        consoleSelected = 0;
                        consolePopup = true;
                      "
                      icon-after
                      >View Console
                    </vs-button>
                  </div>
                  <div class="rounded-lg flex flex-col items-center justify-center z-10" style="background: #f6f588">
                    <div class="flex flex-col items-center justify-center p-4">
                      <img :src="items[1].image" width="45" alt="" class="mb-2" />
                      <div style="max-width: 200px">
                        <h4 class="font-bold text-black text-center mb-1">{{ items[1].name }}</h4>
                        <h5 class="font-light text-black text-center">IP: {{ items[1].ip }}</h5>
                      </div>
                    </div>
                    <vs-button
                      style="border-radius: 0 0 6px 6px"
                      color="#181d2a"
                      icon-pack="feather"
                      icon="icon-external-link"
                      class="w-full"
                      @click="
                        consoleSelected = 1;
                        consolePopup = true;
                      "
                      icon-after
                      >View Console
                    </vs-button>
                  </div>
                  <h5 class="text-center">CORP.LANDSTER.LOCAL</h5>
                </div>
              </div>
            </div>
            <div class="vx-col w-1/4" style="padding-top: 120px">
              <div class="flex flex-col items-center justify-end rounded-lg">
                <div class="flex justify-center items-center">
                  <div class="line-down-other" />
                </div>
                <div class="flex flex-col border-2 rounded-lg p-4" style="gap: 15px; border-style: dashed; border-color: #0cdc62">
                  <div class="rounded-lg flex flex-col items-center justify-center z-10" style="background: #0cdc62">
                    <div class="flex flex-col items-center justify-center p-4" style="min-height: 150px">
                      <img :src="items[2].image" width="150" alt="" class="mb-4" />
                      <div style="max-width: 200px">
                        <h4 class="font-bold text-black text-center mb-1">{{ items[2].name }}</h4>
                        <h5 class="font-light text-black text-center">IP: {{ items[2].ip }}</h5>
                      </div>
                    </div>
                    <vs-button
                      style="border-radius: 0 0 6px 6px"
                      color="#181d2a"
                      icon-pack="feather"
                      icon="icon-external-link"
                      class="w-full"
                      @click="
                        consoleSelected = 2;
                        consolePopup = true;
                      "
                      icon-after
                      >View Console
                    </vs-button>
                  </div>
                  <div class="rounded-lg flex flex-col items-center justify-center z-10" style="background: #0cdc62">
                    <div class="flex flex-col items-center justify-center p-4" style="min-height: 155px">
                      <img :src="items[3].image" width="160" alt="" class="mb-4 mt-2" />
                      <div style="max-width: 200px">
                        <h4 class="font-bold text-black text-center mb-1">{{ items[3].name }}</h4>
                        <h5 class="font-light text-black text-center">IP: {{ items[3].ip }}</h5>
                      </div>
                    </div>
                    <vs-button
                      style="border-radius: 0 0 6px 6px"
                      color="#181d2a"
                      icon-pack="feather"
                      icon="icon-external-link"
                      class="w-full"
                      @click="
                        consoleSelected = 3;
                        consolePopup = true;
                      "
                      icon-after
                      >View Console
                    </vs-button>
                  </div>
                  <h5 class="text-center">DMZ.LANDSTER.LOCAL</h5>
                </div>
              </div>
            </div>
            <div class="vx-col w-1/4" style="padding-top: 120px">
              <div class="flex flex-col items-center justify-end rounded-lg">
                <div class="flex justify-center items-center">
                  <div class="line-down-other" id="hello2" />
                </div>
                <div class="flex flex-col border-2 rounded-lg p-4" style="gap: 15px; border-style: dashed; border-color: #ff9c45">
                  <div class="rounded-lg flex flex-col items-center justify-center z-10" style="background: #ff9c45">
                    <div class="flex flex-col items-center justify-center p-4" style="min-height: 170px">
                      <img :src="items[4].image" width="160" alt="" class="mb-2 mt-2" />
                      <div style="max-width: 200px">
                        <h4 class="font-bold text-black text-center mb-1">{{ items[4].name }}</h4>
                        <h5 class="font-light text-black text-center">IP: {{ items[4].ip }}</h5>
                      </div>
                    </div>
                    <vs-button
                      style="border-radius: 0 0 6px 6px"
                      color="#181d2a"
                      icon-pack="feather"
                      icon="icon-external-link"
                      class="w-full"
                      @click="
                        consoleSelected = 4;
                        consolePopup = true;
                      "
                      icon-after
                      >View Console
                    </vs-button>
                  </div>
                  <div class="rounded-lg flex flex-col items-center justify-center z-10" style="background: #ff9c45">
                    <div class="flex flex-col items-center justify-center p-4" style="min-height: 150px">
                      <img :src="items[5].image" width="160" alt="" class="mb-2 mt-2" />
                      <div style="max-width: 200px">
                        <h4 class="font-bold text-black text-center mb-1">{{ items[5].name }}</h4>
                        <h5 class="font-light text-black text-center">IP: {{ items[5].ip }}</h5>
                      </div>
                    </div>
                    <vs-button
                      style="border-radius: 0 0 6px 6px"
                      color="#181d2a"
                      icon-pack="feather"
                      icon="icon-external-link"
                      class="w-full"
                      @click="
                        consoleSelected = 5;
                        consolePopup = true;
                      "
                      icon-after
                      >View Console
                    </vs-button>
                  </div>
                  <h5 class="text-center">SERV.LANDSTER.LOCAL</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <movable-popup
      refVal="consolePopupRef"
      :title="`${items[consoleSelected].name} - ${items[consoleSelected].ip} - ${items[consoleSelected].network}`"
      width="51vw"
      main-bg="#02050d"
      :active="consolePopup"
      v-on:close="consolePopup = false"
      padding="0"
    >
      <template v-slot:content>
        <div class="pt-1">
          <vs-tabs color="white">
            <vs-tab label="NMAP Scanner" icon-pack="feather" icon="icon-terminal" style="padding: 2px 0 0 0">
              <div class="p-4 text-success" style="background: #040915; max-height: 50vh; overflow: auto">
                <div v-html="items[consoleSelected].nmap" class="rounded-lg" />
              </div>
            </vs-tab>
            <vs-tab label="Nessus Scanner" icon-pack="feather" icon="icon-search" style="padding: 2px 0 0 0">
              <div class="p-12 pb-8" style="background: #040915">
                <div class="flex justify-between items-center mb-8">
                  <img
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/456907876376510464/nessus-logo.png"
                    width="200"
                  />
                  <h1 class="text-4xl">
                    Scan Report -
                    {{
                      Intl.DateTimeFormat('en-US', {
                        dateStyle: 'short',
                        timeStyle: 'short',
                      }).format(new Date())
                    }}
                  </h1>
                </div>

                <div
                  class="vx-row rounded-lg mb-4"
                  :style="`background: rgba(${item.rgbaColor}); border: solid ${item.color} 3px;`"
                  v-for="item in items[consoleSelected].nessus"
                >
                  <div class="w-1/5 flex justify-center items-center p-4">
                    <h1 class="font-bold">
                      {{ item.severity }}
                    </h1>
                  </div>
                  <div class="w-1/5 flex justify-center items-center p-4">
                    <h1>{{ item.cvss }}</h1>
                  </div>
                  <div class="w-3/5 p-1">
                    {{ item.text }}
                  </div>
                </div>
              </div>
            </vs-tab>
            <vs-tab label="Remediation(s)" icon-pack="feather" icon="icon-activity" style="padding: 2px 0 0 0">
              <div class="p-12" style="background: #040915">
                <div class="flex flex-col justify-center items-center mb-8">
                  <img
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/456907876376510464/landster-logo.png"
                    width="250"
                    class="mb-6"
                  />
                  <h1>Vulnerability Remediation Survey</h1>
                </div>

                <div class="flex flex-col border-2 rounded-lg p-4" style="gap: 15px; border-style: dashed; border-color: #f6f588">
                  <div class="vx-row">
                    <div class="vx-col w-1/3">
                      <div class="rounded-lg flex flex-col items-center justify-center z-10 h-full" style="background: #f6f588">
                        <div class="flex flex-col items-center justify-center p-4">
                          <img :src="items[consoleSelected].image" height="30" alt="" class="mb-2" />
                          <div style="max-width: 200px">
                            <h4 class="font-bold text-black text-center mb-1">{{ items[consoleSelected].name }}</h4>
                            <h5 class="font-light text-black text-center">IP: {{ items[consoleSelected].ip }}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vx-col w-2/3">
                      <div class="rounded-lg flex flex-col items-center justify-center z-10 h-full" style="background: #f6f588">
                        <div class="flex flex-col items-center justify-center p-4">
                          <h5 class="text-black text-center mb-3">
                            Select The Appropriate Remediation(s) For The Attached Endpoint Device Based On The NMAP & Nessus Scan
                          </h5>
                          <div v-for="(item, index) in items[consoleSelected].values" class="w-full flex items-center justify-center">
                            <feather-icon v-if="item.correct === true" icon="CheckCircleIcon" svgClasses="h-10 w-8 text-success mr-2" />
                            <feather-icon v-if="item.correct === false" icon="XCircleIcon" svgClasses="h-10 w-8 text-danger mr-2" />
                            <v-select
                              :placeholder="`Select Remediation #${index + 1}`"
                              class="w-full mb-2"
                              :options="options"
                              v-model="item.value"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </template>
    </movable-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Prism from 'vue-prism-component';
import LottieAnimation from '../../../../../../../../../../src/components/lottie/LottieAnimation.vue';
import VueCommand from 'vue-command';
import 'vue-command/dist/vue-command.css';
import MovablePopup from '../../../../../../../../../components/popup/MovablePopup';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      consolePopup: false,
      consoleSelected: 0,

      items: [
        {
          name: 'Employee Workstation',
          ip: '10.60.0.35',
          network: 'CORP.LANDSTER.LOCAL',
          image:
            'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/456907876376510464/windows-computer.png',
          nmap: 'Starting Nmap 7.91 ( https://nmap.org ) at 2022-11-03 14:15 EST <br>Nmap scan report for 10.60.0.35 <br>Host is up (0.00040s latency). <br>Not shown: 997 closed ports <br>PORT STATE SERVICE VERSION <br>135/tcp open msrpc Microsoft Windows RPC <br>445/tcp open microsoft-ds Windows Server 2016 Standard 14393 microsoft-ds (workgroup: WORKGROUP) <br>| smb-security-mode: <br>| authentication_level: user <br>| challenge_response: supported <br>| smb-os-discovery: <br>| OS: Windows Server 2016 Standard 14393 (Windows Server 2016 Standard 6.3) <br>| Computer name: WIN-SERVER <br>| NetBIOS computer name: WIN-SERVER\x00 <br>|_Workgroup: WORKGROUP\x00 <br> <br>OS fingerprint: <br>Windows Server 2016 Standard 14393 - Windows Server 2016 Standard 6.3 <br> <br>Nmap done: 1 IP address (1 host up) scanned in 0.44 seconds <br> <br>Read data files from: /usr/bin/../share/nmap <br>Nmap done: 1 IP address (1 host up) scanned in 0.44 seconds',
          nessus: [
            {
              color: '#c93738',
              rgbaColor: '201, 55, 56, 0.3',
              severity: 'CRITICAL',
              cvss: '10.0',
              text: 'M638-028 HTTP.sys - Remote Code Execution (Missing Urgent Security Updates) - This operating system vulnerability allows a remote attacker to execute arbitrary code on the affected system. The vulnerability exists due to a flaw in the way the operating system handles certain network requests. An attacker can exploit this vulnerability by sending a specially crafted network request to the affected system.',
            },
          ],
          remediations: ['Apply Latest OS Security Updates (Patches)'],
          values: [
            {
              value: null,
              correct: null,
            },
          ],
        },
        {
          name: 'Employee Workstation',
          ip: '10.60.0.40',
          network: 'CORP.LANDSTER.LOCAL',
          image:
            'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/456907876376510464/windows-computer.png',
          nmap: 'Starting Nmap 7.91 ( https://nmap.org ) at 2022-11-03 14:15 EST <br>Nmap scan report for 192.168.10.100 <br>Host is up (0.00040s latency). <br>Not shown: 999 closed ports <br>PORT STATE SERVICE VERSION <br>139/tcp open microsoft-ds Windows Server 2016 <br>445/tcp open microsoft-ds Windows Server 2016 <br>593/tcp open ms-wbt-server Microsoft Terminal Services <br>5900/tcp open vnc VNC (protocol 4.1) <br> <br>OS fingerprint: <br>Windows Server 2016 <br> <br>Nmap done: 1 IP address (1 host up) scanned in 0.44 seconds <br> <br>Read data files from: /usr/bin/../share/nmap <br>Nmap done: 1 IP address (1 host up) scanned in 0.44 seconds',
          nessus: [
            {
              color: '#0cdc62',
              severity: 'LOW',
              cvss: '2.1',
              text: 'TCP Timestamps (Uptime Calculation) - TCP timestamps are a feature of the Transmission Control Protocol (TCP) that allows for more accurate measurement of round-trip time (RTT) in a network. However, this feature can also be used to calculate the uptime of a host, which can be a security concern. Consider Patching the Application/Service handling TCP timestamps on the system.',
            },
          ],
          remediations: ['Apply Application Patches'],
          values: [
            {
              value: null,
              correct: null,
            },
          ],
        },
        {
          name: 'Corporate HTTP/s Web Server',
          ip: '192.168.10.100',
          network: 'DMZ.LANDSTER.LOCAL',
          image:
            'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/456907876376510464/apache-server-logo.png',
          nmap: 'Starting Nmap 7.91 ( https://nmap.org ) at 2022-11-03 14:15 EST<br> Nmap scan report for 192.168.10.100<br> Host is up (0.00040s latency).<br> <br> Not shown: 999 closed ports<br> PORT   STATE SERVICE VERSION<br> 21/tcp closed ftp<br> 22/tcp closed ssh<br> 23/tcp closed telnet<br> 25/tcp closed smtp<br> 80/tcp open  http Apache httpd 2.4.29 mod_ssl/2.4.29 OpenSSL/2.0.22<br> 110/tcp closed pop3<br> 143/tcp closed imap<br> 443/tcp open  ssl/http Apache httpd 2.4.29 mod_ssl/2.4.29 OpenSSL/2.0.22<br> |_http-server-header: Apache/2.4.29 (Ubuntu)<br> |_http-title: Apache2 Ubuntu Default Page: Landster<br> | ssl-cert: Subject: commonName=landster.com<br> | Subject Alternative Name: DNS:www.landster.com<br> | Not valid before: 2022-10-03T00:00:00<br> |_Not valid after:  2025-11-02T23:59:59<br> <br> OS fingerprint:<br> Ubuntu 18.04.5 LTS (Bionic Beaver)<br> <br> Nmap done: 1 IP address (1 host up) scanned in 0.44 seconds<br> <br> Read data files from: /usr/bin/../share<br>map<br> Nmap done: 1 IP address (1 host up) scanned in 0.44 seconds<br>',
          nessus: [
            {
              color: '#ff4747',
              severity: 'CRITICAL',
              cvss: '10.0',
              text: 'Detection of Outdated SSLv2 and SSLv3 Protocols (Deprecation Warning) - The host is using a deprecated version of SSL, SSLv2 which is vulnerable to known attacks. An attacker may be able to intercept and decrypt sensitive information such as login credentials and financial information.',
            },
          ],
          remediations: ['Create a New Certificate Signing Request (CSR)', 'Upgrade Encryption Standards (Cipher Suite)'],
          values: [
            {
              value: null,
              correct: null,
            },
            {
              value: null,
              correct: null,
            },
          ],
        },
        {
          name: 'Corporate HTTP/s Web Server',
          ip: '192.168.10.105',
          network: 'DMZ.LANDSTER.LOCAL',

          image: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/456907876376510464/nginx-logo.png',
          nmap: 'Starting Nmap 7.91 ( https://nmap.org ) at 2022-11-03 14:15 EST <br>Nmap scan report for 192.168.10.105 <br>Host is up (0.00040s latency). <br>Not shown: 999 closed ports <br>PORT    STATE SERVICE    VERSION <br>21/tcp  open ftp <br>22/tcp  closed ssh <br>23/tcp  closed telnet <br>25/tcp  closed smtp <br>80/tcp  open  http       nginx 1.14.0 <br>110/tcp closed pop3 <br>143/tcp closed imap <br>443/tcp open  ssl/http   nginx 1.14.0 <br>|_http-server-header: nginx/1.14.0 <br>|_http-title: Welcome to nginx! <br>| ssl-cert: Subject: commonName=example.com <br>| Subject Alternative Name: DNS:example.com <br>| Not valid before: 2022-10-03T00:00:00 <br>|_Not valid after:  2022-11-02T23:59:59 <br> <br>OS fingerprint: <br>Ubuntu 18.04.5 LTS (Bionic Beaver) <br> <br>Nmap done: 1 IP address (1 host up) scanned in 0.44 seconds <br> <br>Read data files from: /usr/bin/../share/nmap <br>Nmap done: 1 IP address (1 host up) scanned in 0.44 seconds',
          nessus: [
            {
              color: '#ff4747',
              severity: 'CRITICAL',
              cvss: '10.0',
              text: 'Information Exposure (Unsecure File System Access) - A remote attacker can access sensitive information on the affected system through the unsecure open FTP port 21. Ensuring port 21 is closed will prevent unethical use of the unnecessary port.',
            },
            {
              color: '#fd7830',
              severity: 'HIGH',
              cvss: '7.2',
              text: 'Detection of EOL Operating System - The server is running an older version of Ubuntu, which could make it more vulnerable to certain attacks. An attacker may be able to use CVE databases to find potential holes in the system security due to operating system having an end of life maintenance status.',
            },
          ],
          remediations: ['Close Potentially Vulnerable Ports', 'Update Operating System Version'],
          values: [
            {
              value: null,
              correct: null,
            },
            {
              value: null,
              correct: null,
            },
          ],
        },
        {
          name: 'Remote Tunnel VPN Server',
          ip: '10.0.0.50',
          network: 'SERV.LANDSTER.LOCAL',
          image: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/456907876376510464/openvpn-logo.png',
          nmap: 'Starting Nmap 7.91 ( https://nmap.org ) at 2023-01-28 16:32 EST <br>Nmap scan report for 10.0.0.50 <br>Host is up (0.00029s latency). <br>Not shown: 999 closed ports <br>PORT STATE SERVICE <br>1194/tcp open openvpn <br>21/tcp closed ftp <br>22/tcp closed ssh <br>23/tcp closed telnet <br>25/tcp closed smtp <br>80/tcp closed http <br>110/tcp closed pop3 <br>143/tcp closed imap <br>443/tcp closed ssl/https <br> <br>OS fingerprint: <br>Ubuntu 18.04.5 LTS (Bionic Beaver) <br> <br>Nmap done: 1 IP address (1 host up) scanned in 0.12 seconds',
          nessus: [
            {
              color: '#fd7830',
              severity: 'HIGH',
              cvss: '7.3',
              text: 'A vulnerability in systems using a certificate signed with a weak signature algorithm allows a remote attacker to impersonate the affected system and intercept sensitive information. The vulnerability exists due to the use of a weak signature algorithm in the certificate, which can be easily broken by an attacker. It would be recommended to generate a new certificate with a strong signature algorithm.',
            },
          ],
          remediations: ['Create a New Certificate Signing Request (CSR)'],
          values: [
            {
              value: null,
              correct: null,
            },
          ],
        },
        {
          name: 'NoSQL Document Server',
          ip: '10.0.0.55',
          network: 'SERV.LANDSTER.LOCAL',

          image: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/456907876376510464/mongodb-logo.png',
          nmap: 'Starting Nmap 7.91 ( https://nmap.org ) at 2023-01-28 12:00 EST <br>Nmap scan report for 10.0.0.50 <br>Host is up (0.00040s latency). <br>Not shown: 998 closed ports <br>PORT STATE SERVICE <br>22/tcp closed ssh <br>23/tcp closed telnet <br>25/tcp closed smtp <br>80/tcp closed http <br>110/tcp closed pop3 <br>143/tcp closed imap <br>443/tcp closed https <br>21/tcp closed ftp <br>27017/tcp open mongodb <br> <br>OS fingerprint: <br>Ubuntu 18.04.5 LTS (Bionic Beaver) <br> <br>Nmap done: 1 IP address (1 host up) scanned in 0.44 seconds',
          nessus: [
            {
              color: '#ff4747',
              severity: 'CRITICAL',
              cvss: '9.2',
              text: 'Insecure Default Configuration with Remote Password Auth - A vulnerability in MongoDB databases with weak default credentials allows a remote attacker to gain unauthorized access to the affected database and compromise sensitive information. The vulnerability exists due to the use of weak default credentials for the database, which can be easily guessed or cracked by an attacker.',
            },
            {
              color: '#ff4747',
              severity: 'CRITICAL',
              cvss: '10.0',
              text: 'Multiple Vulnerabilities: Local Privilege Escalation & Security Bypass - A remote attacker can gain elevated privileges on the affected MongoDB database and compromise sensitive information. Install the latest MongoDB updates from the mongo repo to address the privilege escalation vulnerability.',
            },
          ],
          remediations: ['Change Default Login Credentials', 'Apply Application Patches'],
          values: [
            {
              value: null,
              correct: null,
            },
            {
              value: null,
              correct: null,
            },
          ],
        },
      ],

      options: [
        'Remove Unnecessary Software Apps',
        'Upgrade System Memory (RAM)',
        'Upgrade Encryption Standards (Cipher Suite)',
        'Refresh Anti-Malware Identification Signatures',
        'Use a Firewall To Control Network Access',
        'Close Potentially Vulnerable Ports',
        'Apply Application Patches',
        'Update Operating System Version',
        'Implement Multi-Factor Authentication (MFA)',
        'Change Default Login Credentials',
        'Regularly Review and Monitor System Logs',
        'Create a New Certificate Signing Request (CSR)',
        'Apply Latest OS Security Updates (Patches)',
      ],
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;

      this.items.forEach((i, iI) => {
        const answers = [...i.remediations];
        i.values.forEach((v, vI) => {
          this.items[iI].values[vI].correct = false;
          answers.forEach((a, aI) => {
            if (v.value === a) {
              totalScore++;
              this.items[iI].values[vI].correct = true;
              answers.splice(aI, 1);
            }
          });
        });
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    vSelect,
    LottieAnimation,
    MovablePopup,
    VueCommand,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.line-down-left {
  position: absolute;
  width: 4px;
  height: 350px;
  background-color: #ffffff;
}

.line-down-other {
  position: absolute;
  width: 4px;
  height: 87px;
  background-color: #ffffff;
}

.line-across-top {
  position: absolute;
  width: 77.5%;
  height: 4px;
  background-color: #ffffff;
}
</style>
